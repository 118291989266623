import { FC, useMemo } from 'react';

import { Typography } from '@mui/material';
import { format, getDate, getYear, isEqual, startOfDay } from 'date-fns';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import { SmartFilterDescriptionState } from 'components/common/SmartFilter/enums';

import { useGetReportVacationsQuery } from 'domain/report/vacation/apiSlice';

import { stringToDate } from 'utils/calendar';
import { DATE_FORMAT_FULL, formatToDateWithFixedTimeZone } from 'utils/dateTime';
import { getFullName } from 'utils/person';

import Info from './components/Info';
import Table from './components/Table';
import { getColoredAccessors } from './components/Table/service';
import TableBodyTotalCell from './components/TableBodyTotalCell';
import TableHeaderMonth from './components/TableHeaderMonth';
import TableHeaderTotalCell from './components/TableHeaderTotalCell';
import TableNameCell from './components/TableNameCell';
import { ReportVacationsCalendarTableColumnAccessor } from './enums';
import { getYears, groupeDateRangeByMonth } from './service';
import styles from './styles';
import { IVacationsCalendarProps } from './types';

const Calendar: FC<IVacationsCalendarProps> = props => {
  const { filters, onDateRangeChange } = props;

  const { t } = useTranslation('reportVacations');

  const stateEq = filters.stateEq === SmartFilterDescriptionState.all ? undefined : filters.stateEq;

  const {
    data: reportVacationsData,
    isLoading: isReportVacationsDataLoading,
    isFetching: isReportVacationsDataFetching,
    isError: isReportVacationsDataLoadingError,
  } = useGetReportVacationsQuery({ ...filters, stateEq });

  const resources = reportVacationsData?.resources ?? [];

  const { startDate, endDate } = filters;
  const dateRangeByMonths = useMemo(() => groupeDateRangeByMonth(startDate, endDate), [startDate, endDate]);

  const years = getYears(startDate);
  const { daysByMonth, sortedMonthKeys, weekendsAccessors, lastDayOfMonthAccessors } = dateRangeByMonths;

  const RESOURCES_LIST_COLUMNS = useMemo(() => {
    const stickyHeaders = [
      {
        Header: '',
        accessor: ReportVacationsCalendarTableColumnAccessor.resourcesEmpty,
        columns: [
          {
            Header: (
              <Typography sx={styles.resourceHeaderTitle} variant="body2">
                {t('calendar.tableHeaders.resource')}
              </Typography>
            ),
            accessor: ReportVacationsCalendarTableColumnAccessor.resourcesEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationsCalendarTableColumnAccessor.resources,
                minWidth: '250px',
              },
            ],
          },
        ],
      },
      {
        Header: '',
        accessor: ReportVacationsCalendarTableColumnAccessor.carryoverPreviousEmpty,
        columns: [
          {
            Header: (
              <TableHeaderTotalCell
                title={t('calendar.tableHeaders.carryoverPrevious', { count: years.previousYear })}
              />
            ),
            accessor: ReportVacationsCalendarTableColumnAccessor.carryoverPreviousEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationsCalendarTableColumnAccessor.carryoverPrevious,
                width: '35px',
                minWidth: '35px',
              },
            ],
          },
        ],
      },
      {
        Header: '',
        accessor: ReportVacationsCalendarTableColumnAccessor.availableEmpty,
        columns: [
          {
            Header: <TableHeaderTotalCell title={t('calendar.tableHeaders.available', { count: years.currentYear })} />,
            accessor: ReportVacationsCalendarTableColumnAccessor.availableEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationsCalendarTableColumnAccessor.available,
                width: '35px',
                minWidth: '35px',
              },
            ],
          },
        ],
      },
      {
        Header: '',
        accessor: ReportVacationsCalendarTableColumnAccessor.scheduledEmpty,
        columns: [
          {
            Header: <TableHeaderTotalCell title={t('calendar.tableHeaders.scheduled', { count: years.currentYear })} />,
            accessor: ReportVacationsCalendarTableColumnAccessor.scheduledEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationsCalendarTableColumnAccessor.scheduled,
                width: '35px',
                minWidth: '35px',
              },
            ],
          },
        ],
      },
      {
        Header: '',
        accessor: ReportVacationsCalendarTableColumnAccessor.carryoverNextEmpty,
        columns: [
          {
            Header: (
              <TableHeaderTotalCell title={t('calendar.tableHeaders.carryoverNext', { count: years.nextYear })} />
            ),
            accessor: ReportVacationsCalendarTableColumnAccessor.carryoverNextEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationsCalendarTableColumnAccessor.carryoverNext,
                width: '35px',
                minWidth: '35px',
              },
            ],
          },
        ],
      },
    ];

    const monthHeaders = sortedMonthKeys.map(monthKey => {
      const monthData = daysByMonth[monthKey];
      const { name, days, startMonthDate, endMonthDate } = monthData;

      const year = getYear(formatToDateWithFixedTimeZone(monthKey));
      const firstDayOfMonth = getDate(startMonthDate);
      const lastDayOfMonth = getDate(endMonthDate);
      const currentDate = startOfDay(new Date());

      const dayColumns = days.map(date => {
        const isToday = isEqual(date, currentDate);

        return {
          Header: (
            <Typography
              sx={MUISx(styles.dateHeaderTitle, {
                condition: isToday,
                sx: styles.currentDateHeader,
              })}
              variant="h6"
            >
              {getDate(date)}
            </Typography>
          ),
          accessor: format(date, DATE_FORMAT_FULL),
          minWidth: '35px',
          width: '35px',
        };
      });

      return {
        Header: '',
        accessor: `${monthKey}-empty:headerColumn`,
        columns: [
          {
            Header: (
              <TableHeaderMonth
                title={name}
                firstDayOfMonth={firstDayOfMonth}
                lastDayOfMonth={lastDayOfMonth}
                year={year}
              />
            ),
            accessor: `${monthKey}:headerColumn`,
            columns: dayColumns,
          },
        ],
      };
    });

    return [...stickyHeaders, ...monthHeaders];
  }, [dateRangeByMonths]);

  const tableData = useMemo(() => {
    return resources.map(resource => {
      const fullName = getFullName(resource);

      return {
        [ReportVacationsCalendarTableColumnAccessor.resources]: <TableNameCell fullName={fullName} />,
        [ReportVacationsCalendarTableColumnAccessor.carryoverPrevious]: (
          <TableBodyTotalCell total={resource.vacationTotals?.carryoverPrev ?? 0} />
        ),
        [ReportVacationsCalendarTableColumnAccessor.available]: (
          <TableBodyTotalCell total={resource.vacationTotals?.available ?? 0} />
        ),
        [ReportVacationsCalendarTableColumnAccessor.scheduled]: (
          <TableBodyTotalCell total={resource.vacationTotals?.scheduled ?? 0} />
        ),
        [ReportVacationsCalendarTableColumnAccessor.carryoverNext]: (
          <TableBodyTotalCell total={resource.vacationTotals?.carryoverNext ?? 0} />
        ),
      };
    });
  }, [resources]);

  const coloredAccessors = getColoredAccessors(resources);

  if (isReportVacationsDataLoading) {
    return <Loader />;
  }

  return (
    <Div sx={styles.root}>
      <Info startDate={stringToDate(startDate)} endDate={stringToDate(endDate)} onDateRangeChange={onDateRangeChange} />
      <Table
        isLoading={isReportVacationsDataFetching}
        isError={isReportVacationsDataLoadingError}
        data={tableData}
        columns={RESOURCES_LIST_COLUMNS}
        coloredAccessors={coloredAccessors}
        weekendsAccessors={weekendsAccessors}
        lastDayOfMonthAccessors={lastDayOfMonthAccessors}
      />
    </Div>
  );
};

export default Calendar;
