import { FC } from 'react';

import DateRangePicker from 'components/common/DateRangePicker';
import Div from 'components/common/Div';

import { getDateRange, RangeType } from 'utils/dateRangePicker';

import TableLegend from './components/TableLegend';
import styles from './styles';
import { ITableVacationsInfo } from './types';

const Info: FC<ITableVacationsInfo> = props => {
  const { startDate, endDate, onDateRangeChange } = props;

  return (
    <Div sx={styles.root}>
      <TableLegend />
      <DateRangePicker
        currentDateRange={getDateRange(startDate, endDate, RangeType.month)}
        onDateRangeChange={onDateRangeChange}
      />
    </Div>
  );
};

export default Info;
