import { TagDescription } from '@reduxjs/toolkit/query';

export enum TagTypes {
  CurrentUser = 'currentUser',
  CurrentUserImpersonation = 'currentUserImpersonation',
  User = 'user',
  Company = 'company',
  CompanySettings = 'companySettings',
  Resource = 'resource',
  Project = 'project',
  ProjectTask = 'projectTask',
  ResourceUsage = 'resourceUsage',
  ProjectBudgetMonth = 'projectBudgetMonth',
  Technology = 'technology',
  Tag = 'tag',
  ReportHoursByResource = 'reportHoursByResource',
  ReportAllocation = 'reportAllocation',
  ReportHoursByProject = 'reportHoursByProject',
  Group = 'group',
  AccessLevel = 'accessLevel',
  ResourceTrackedTimeEntry = 'resourceTrackedTimeEntry',
  ResourceFavoriteTimeEntry = 'resourceFavoriteTimeEntry',
  ResourceUsageTechnology = 'resourceUsageTechnology',
  ResourceVacation = 'resourceVacation',
  AccessLevelTechnology = 'accessLevelTechnology',
  UserProfileStack = 'userProfileStack',
  UserProfileProject = 'userProfileProject',
  UserProfileAccessLevel = 'userProfileAccessLevel',
  UserProfileMigrations = 'userProfileMigrations',
  UserHoursPerWeek = 'userHoursPerWeek',
  UserExpectedBillableTimePercent = 'userExpectedBillableTimePercent',
  HolidayCalendar = 'holidayCalendar',
  TimeTrackerTrackable = 'timeTrackerTrackable',
  ReportTrackedTimeEntry = 'reportTrackedTimeEntry',
  ReportTrackedTimeEntryGroupedByUser = 'reportTrackedTimeEntryGroupedByUser',
  ReportTrackedTimeEntryUsersList = 'reportTrackedTimeEntryUsersList',
  HolidayCalendarYear = 'holidayCalendarYear',
  HolidayCalendarDay = 'holidayCalendarDay',
  Export = 'export',
  Vacancy = 'vacancy',
  ResourceAbility = 'resourceAbility',
  TrackingType = 'trackingType',
  ReportVacation = 'reportVacation',
  ReportVacationRequest = 'reportVacationRequest',
  ReportVacationRequestProjects = 'reportVacationRequestProjects',
  ReportVacationRequestSpecializations = 'reportVacationRequestSpecializations',
  ForbiddenWord = 'forbiddenWord',
  Filter = 'filter',
  СurrentUserPermissions = 'currentUserPermissions',
}

export const LIST_ID = 'LIST';

export const invalidateTagsList =
  <T extends TagTypes>(...tags: Array<T>) =>
  <R, E>(_result: R, error: E): Array<TagDescription<TagTypes>> =>
    error ? [] : tags.map(tag => getListTag(tag));

export const invalidateTags =
  (...tags: Array<TagDescription<TagTypes>>) =>
  <R, E>(_result: R, error: E) =>
    error ? [] : tags;

export const provideTags = (tagType: TagTypes, tags?: Array<{ id: string | number }>) => {
  const collectionListTag = getListTag(tagType);

  if (tags) {
    return [...tags.map(({ id }) => getItemTag(tagType, id)), collectionListTag];
  }

  return [collectionListTag];
};

export const getItemTag = (type: TagTypes, id: string | number) => ({
  type,
  id,
});

export const getListTag = (type: TagTypes): TagDescription<TagTypes> => ({
  type,
  id: LIST_ID,
});
