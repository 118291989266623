import { FC, useState } from 'react';

import { Alert, SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import Pagination from 'components/common/TablePagination';

import { useGetReportVacationRequestsQuery } from 'domain/report/vacationRequest/apiSlice';

import { IPaginationParameters } from 'types/api';

import { defaultPaginationParameters } from 'utils/pagination';

import { VacationRequestState } from '../../enums';

import VacationRequestItem from './components/VacationRequestItem';
import VacationRequestItemsHeader from './components/VacationRequestItemsHeader';
import styles from './styles';
import { IVacationRequestsCalendarProps } from './types';

const VacationRequests: FC<IVacationRequestsCalendarProps> = props => {
  const { smartFilter } = props;
  const { t } = useTranslation(['common', 'reportVacations', 'table']);

  const [paginationParameters, setPaginationParameters] = useState<IPaginationParameters>({
    pageNumber: defaultPaginationParameters.pageNumber,
    pageSize: defaultPaginationParameters.pageSize,
  });

  const vacationRequestStateEq =
    smartFilter.vacationRequestStateEq === VacationRequestState.All ? undefined : smartFilter.vacationRequestStateEq;

  const {
    data: reportVacationRequestsData,
    isLoading: isReportVacationRequestsDataLoading,
    isError: isReportVacationRequestsDataLoadingError,
    isFetching: isReportVacationRequestsDataFetching,
  } = useGetReportVacationRequestsQuery({
    ...smartFilter,
    vacationRequestStateEq,
    page: paginationParameters.pageNumber,
    perPage: paginationParameters.pageSize,
  });

  if (isReportVacationRequestsDataLoading || isReportVacationRequestsDataFetching) {
    return <Loader />;
  }

  if (isReportVacationRequestsDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }
  const isTableDataEmpty = reportVacationRequestsData?.vacationApprovements?.length === 0;
  const isDataEmpty = isTableDataEmpty && !isReportVacationRequestsDataLoading;
  const pageCount = reportVacationRequestsData?.meta?.totalPages ?? 0;
  const pageSize = paginationParameters.pageSize;
  const pageIndex = paginationParameters.pageNumber - 1;

  const handleCurrentPageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setPaginationParameters({ pageNumber: page, pageSize: paginationParameters.pageSize });
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const pageSize = Number(event.target.value);
    setPaginationParameters({ pageNumber: 1, pageSize });
  };

  return (
    <Div sx={styles.root}>
      <VacationRequestItemsHeader />
      <Div sx={styles.items}>
        {reportVacationRequestsData?.vacationApprovements?.map((approvement, index) => {
          const {
            id: approvementId,
            resourceVacation,
            state: approvementState,
            approvingLevelName,
            approvingLevelType,
          } = approvement;

          return (
            <VacationRequestItem
              key={`${approvementId}${resourceVacation.id}`}
              vacationRequest={resourceVacation}
              approvementId={approvementId}
              approvementState={approvementState}
              withBackground={index % 2 === 0}
              approvingLevelName={approvingLevelName ?? ''}
              approvingLevelType={approvingLevelType}
            />
          );
        })}
      </Div>

      {isTableDataEmpty && (
        <Typography textAlign="center" sx={{ marginTop: 1 }}>
          {t('noData')}
        </Typography>
      )}

      <Pagination
        isDataEmpty={isDataEmpty}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        handleCurrentPageChange={handleCurrentPageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </Div>
  );
};

export default VacationRequests;
