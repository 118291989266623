import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'background.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    marginBottom: 3,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  group: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingY: 1.5,
    paddingX: 2,
    backgroundColor: 'background.gray',
  },
  headerTitle: {
    color: 'text.blackLight',
  },
  totalEntriesHours: {
    width: '60px',
    marginRight: 9.5,
  },
};

export default styles;
