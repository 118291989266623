import {
  format,
  isToday,
  isYesterday,
  parse,
  hoursToMinutes,
  subDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
  startOfWeek,
  endOfWeek,
  getDaysInMonth,
  eachDayOfInterval,
  isWeekend,
  addMonths,
} from 'date-fns';

import i18n from 'locales/i18n';

import { IWeekStartsOn } from 'types/dates';

export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const MONTHS_IN_YEAR = 12;

export const DATE_FORMAT_FULL = 'yyyy-MM-dd';
export const DATE_FORMAT_YEAR_MONTH = 'yyyy-MM';

export const formatToDateWithFixedTimeZone = (date: string): Date => {
  return new Date(`${date}T12:00:00.000`);
};

export const formatToUTCString = (date: Date): string => {
  return date.toString();
};

export enum DateStyle {
  full = 'full',
  long = 'long',
  medium = 'medium',
  short = 'short',
}

export const formatDateToString = (date: Date, dateStyle: DateStyle = DateStyle.medium): string => {
  return date.toLocaleDateString(Intl.DateTimeFormat().resolvedOptions().locale, {
    dateStyle,
  });
};

export const formatToShortString = (date: Date): string => {
  return date.toLocaleDateString(Intl.DateTimeFormat().resolvedOptions().locale, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

export const parseDate = (dateString: string): Date => {
  return parse(dateString, 'MM/dd/yyyy', new Date());
};

export const parseShortDate = (dateString: string): Date => {
  return parse(dateString, 'MM/dd/yy', new Date());
};

export const convertTimeToString = (dateString: string) => {
  const date = new Date(dateString);

  return format(date, 'HH:mm');
};

export const convertDateToUTCString = (date: Date): string => {
  return format(date, 'dd-MM-yyyy');
};

export const formatDateTime = (dateString: string): string => {
  const date = parse(dateString, DATE_FORMAT_FULL, new Date());
  const formatedDate = format(date, 'E, dd MMM');
  if (isToday(date)) {
    return `${i18n.t('common:today')} (${formatedDate})`;
  }
  if (isYesterday(date)) {
    return `${i18n.t('common:yesterday')} (${formatedDate})`;
  }

  return formatedDate;
};

export interface IMonth {
  title: string;
  id: number;
}

export const zeroPad = value => String(value).padStart(2, '0');

export const zeroPadEnd = value => String(value).padEnd(2, '0');

export const getHoursAndMinutesStringFromMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / MINUTES_PER_HOUR);
  const leftMinutes = minutes % MINUTES_PER_HOUR;

  return `${zeroPad(hours)}:${zeroPad(leftMinutes)}`;
};

export const MONTHS: Array<IMonth> = [
  {
    title: i18n.t('common:months.january'),
    id: 1,
  },
  {
    title: i18n.t('common:months.february'),
    id: 2,
  },
  {
    title: i18n.t('common:months.march'),
    id: 3,
  },
  {
    title: i18n.t('common:months.april'),
    id: 4,
  },
  {
    title: i18n.t('common:months.may'),
    id: 5,
  },
  {
    title: i18n.t('common:months.june'),
    id: 6,
  },
  {
    title: i18n.t('common:months.july'),
    id: 7,
  },
  {
    title: i18n.t('common:months.august'),
    id: 8,
  },
  {
    title: i18n.t('common:months.september'),
    id: 9,
  },
  {
    title: i18n.t('common:months.october'),
    id: 10,
  },
  {
    title: i18n.t('common:months.november'),
    id: 11,
  },
  {
    title: i18n.t('common:months.december'),
    id: 12,
  },
];

export const formatDateToDatePickerString = (date: Date) => {
  if (isToday(date)) {
    return i18n.t('common:today');
  }
  if (isYesterday(date)) {
    return i18n.t('common:yesterday');
  }

  return format(date, 'MM/dd/yyyy');
};

export const getMinutesFromHours = (hours: number): number => hoursToMinutes(hours);

export const getTimeRanges = (startDayOfWeek: IWeekStartsOn) => ({
  startOfWeek: startOfWeek(new Date(), { weekStartsOn: startDayOfWeek }),
  endOfWeek: endOfWeek(new Date(), { weekStartsOn: startDayOfWeek }),
  startOfLastWeek: startOfWeek(subDays(new Date(), 7), { weekStartsOn: startDayOfWeek }),
  endOfLastWeek: endOfWeek(subDays(new Date(), 7), { weekStartsOn: startDayOfWeek }),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(subDays(new Date(), 1)),
  endOfYesterday: endOfDay(subDays(new Date(), 1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(subMonths(new Date(), 1)),
  endOfLastMonth: endOfMonth(subMonths(new Date(), 1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLastYear: startOfYear(subYears(new Date(), 1)),
  endOfLastYear: endOfYear(subYears(new Date(), 1)),
});

export const getCountDaysOfMonth = (year: number, monthCount: number) => {
  return getDaysInMonth(new Date(year, monthCount));
};

export const getRangeDates = (startDate: Date, endDate: Date) => {
  return eachDayOfInterval({ start: startDate, end: endDate });
};

export const getMonthEnName = (date: Date) => {
  return date.toLocaleString('en-US', { month: 'long' });
};

export const getHalfYearRange = () => {
  const date = new Date();
  return {
    startMonthId: subMonths(date, 6).getMonth() + 1,
    startYear: subMonths(date, 6).getFullYear(),
    endMonthId: addMonths(date, 6).getMonth() + 1,
    endYear: addMonths(date, 6).getFullYear(),
  };
};

export const isWeekendDay = (date: Date) => isWeekend(date);

export const getFirstAndLastDateOfMonthForYear = (year, month) => {
  return {
    firstDate: startOfMonth(new Date(year, month, 1)),
    lastDate: endOfMonth(new Date(year, month, 1)),
  };
};

export const parseStringToDate = (dateString: string): Date => {
  return parse(dateString, DATE_FORMAT_FULL, new Date());
};

export const convertDateStringFormat = (date: string) => {
  const parts = date.split('/');
  const year = `20${parts[2]}`;
  const month = parts[0];
  const day = parts[1];
  return `${month}-${day}-${year}`;
};
export const getStringHoursFromDuration = (duration: number) => duration / 60;

export const getStringMinutesFromDuration = (duration: number) => duration % 60;
