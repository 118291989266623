import { format, isValid } from 'date-fns';
import _ from 'lodash';

import { ReportVacationsCalendarTableColumnAccessor } from 'components/pages/Report/Vacations/components/Calendar/enums';

import { IReportVacationResource } from 'domain/report/vacation/types';
import { ResourseVacationState } from 'domain/resource/vacation/enums';

import {
  DATE_FORMAT_FULL,
  formatToDateWithFixedTimeZone,
  getRangeDates,
  isWeekendDay,
  parseStringToDate,
} from 'utils/dateTime';
import { getFullName } from 'utils/person';

import { IReportVacationsCalendarTableColoredAccessorData } from './types';

const isDateFormat = (date: string): boolean => {
  const dateString = date.split(':').at(0) ?? '';
  const parsedDate = parseStringToDate(dateString);

  return isValid(parsedDate) && format(parsedDate, DATE_FORMAT_FULL) === dateString;
};

export const getColumns = (
  columnAccessor: string,
  weekendsAccessors: Array<string>,
  lastDayOfMonthAccessors: Array<string>,
) => {
  return {
    isResourcesColumn: columnAccessor === ReportVacationsCalendarTableColumnAccessor.resources,
    isResourcesEmptyColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.resourcesEmpty}`,
    isResourcesEmptyDepthColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.resourcesEmptyDepth}`,

    isCarryoverPreviousColumn: columnAccessor === ReportVacationsCalendarTableColumnAccessor.carryoverPrevious,
    isAvailableColumn: columnAccessor === ReportVacationsCalendarTableColumnAccessor.available,
    isScheduledColumn: columnAccessor === ReportVacationsCalendarTableColumnAccessor.scheduled,
    isCarryoverNextColumn: columnAccessor === ReportVacationsCalendarTableColumnAccessor.carryoverNext,

    isEmptyCarryoverPreviousColumn:
      columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.carryoverPreviousEmpty}`,
    isEmptyAvailableColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.availableEmpty}`,
    isEmptyScheduledColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.scheduledEmpty}`,
    isEmptyCarryoverNextColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.carryoverNextEmpty}`,

    isEmptyDepthCarryoverPreviousColumn:
      columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.carryoverPreviousEmptyDepth}`,
    isEmptyDepthAvailableColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.availableEmptyDepth}`,
    isEmptyDepthScheduledColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.scheduledEmptyDepth}`,
    isEmptyDepthCarryoverNextColumn:
      columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.carryoverNextEmptyDepth}`,
    isMonthHeaderColumn: columnAccessor.includes(':headerColumn'),
    isDayColumn: isDateFormat(columnAccessor),
    isWeekendColumn: weekendsAccessors.includes(columnAccessor),
    isLastDayOfMonthColumn: lastDayOfMonthAccessors.includes(columnAccessor),
  };
};

const generateAccessors = (rangeDates: Array<Date>) => {
  return rangeDates.filter(date => !isWeekendDay(date)).map(date => format(date, DATE_FORMAT_FULL));
};

export const getColoredAccessors = (
  resources: Array<IReportVacationResource>,
): Array<IReportVacationsCalendarTableColoredAccessorData> => {
  return resources
    .filter(resource => !_.isEmpty(resource.vacations))
    .flatMap(resource => {
      return resource.vacations.map(vacation => {
        const startDate = formatToDateWithFixedTimeZone(vacation.startDate);
        const endDate = formatToDateWithFixedTimeZone(vacation.endDate);
        const rangeDates = getRangeDates(startDate, endDate);

        return {
          id: vacation.id,
          accessors: generateAccessors(rangeDates),
          state: vacation.state,
          resourceFullName: getFullName(resource),
        };
      });
    });
};

const getColumnColorState = (
  currentAccessors: Array<IReportVacationsCalendarTableColoredAccessorData>,
  resourceRowFullName: string,
  state: ResourseVacationState,
) => {
  return !_.isEmpty(
    currentAccessors.filter(
      coloredAccessor => coloredAccessor.state === state && coloredAccessor.resourceFullName === resourceRowFullName,
    ),
  );
};

export const getColumnColor = (
  coloredAccessors: Array<IReportVacationsCalendarTableColoredAccessorData>,
  columnAccessor: string,
  resourceRowFullName: string,
) => {
  const currentAccessors = coloredAccessors.filter(coloredAccessor =>
    coloredAccessor.accessors.includes(columnAccessor),
  );

  const isRequestedState = getColumnColorState(currentAccessors, resourceRowFullName, ResourseVacationState.onApproval);
  const isCompletedState = getColumnColorState(currentAccessors, resourceRowFullName, ResourseVacationState.completed);
  const isApprovedState = getColumnColorState(currentAccessors, resourceRowFullName, ResourseVacationState.approved);
  const isApprovedOnProjectLevel = getColumnColorState(
    currentAccessors,
    resourceRowFullName,
    ResourseVacationState.approvedOnProjectLevel,
  );

  return {
    isCompleted: !_.isEmpty(currentAccessors) && isCompletedState,
    isApproved: !_.isEmpty(currentAccessors) && isApprovedState,
    isApprovedOnProjectLevel: !_.isEmpty(currentAccessors) && isApprovedOnProjectLevel,
    isRequested: !_.isEmpty(currentAccessors) && isRequestedState,
  };
};
