import { eachDayOfInterval, endOfMonth, format, getMonth, getYear, isWeekend, startOfMonth } from 'date-fns';
import _ from 'lodash';

import { DATE_FORMAT_FULL, DATE_FORMAT_YEAR_MONTH, formatToDateWithFixedTimeZone } from 'utils/dateTime';

import { IDateRangeByMonth, IDaysByMonth } from './types';

interface IDateRange {
  start: Date;
  end: Date;
}

const groupDaysByMonth = (dateRange: IDateRange, allRangeDays: Date[]): IDaysByMonth => {
  const { start, end } = dateRange;
  const rangeStartMonthKey = format(start, DATE_FORMAT_YEAR_MONTH);
  const rangeEndMonthKey = format(end, DATE_FORMAT_YEAR_MONTH);

  return allRangeDays.reduce((accumulator, date) => {
    const monthName = format(date, 'LLLL').toLowerCase();
    const monthNumber = getMonth(date) + 1;

    const currentMonthKey = format(date, DATE_FORMAT_YEAR_MONTH);
    const startMonthDate = rangeStartMonthKey === currentMonthKey ? start : startOfMonth(date);
    const endMonthDate = rangeEndMonthKey === currentMonthKey ? end : endOfMonth(date);
    const monthGroupKey = format(startMonthDate, DATE_FORMAT_FULL);

    if (_.has(accumulator, monthGroupKey)) {
      const monthGroup = accumulator[monthGroupKey];
      const { days } = monthGroup;

      return {
        ...accumulator,
        [monthGroupKey]: {
          ...monthGroup,
          days: [...days, date],
        },
      };
    }

    return {
      ...accumulator,
      [monthGroupKey]: {
        name: monthName,
        number: monthNumber,
        startMonthDate,
        endMonthDate,
        days: [date],
      },
    };
  }, {});
};

export const groupeDateRangeByMonth = (startDate: string, endDate: string): IDateRangeByMonth => {
  const rangeStartDate = formatToDateWithFixedTimeZone(startDate);
  const rangeEndDate = formatToDateWithFixedTimeZone(endDate);

  const dateRange = { start: rangeStartDate, end: rangeEndDate };
  const allRangeDays = eachDayOfInterval(dateRange);

  const daysByMonth = groupDaysByMonth(dateRange, allRangeDays);
  const sortedMonthKeys = _.orderBy(Object.keys(daysByMonth), formatToDateWithFixedTimeZone);

  const weekendsAccessors = allRangeDays.reduce(
    (weekends, date) => (isWeekend(date) ? [...weekends, format(date, DATE_FORMAT_FULL)] : weekends),
    [],
  );

  const lastDayOfMonthAccessors = sortedMonthKeys.map(monthKey => {
    const endOfMonthDate = endOfMonth(formatToDateWithFixedTimeZone(monthKey));

    return format(endOfMonthDate, DATE_FORMAT_FULL);
  });

  return {
    daysByMonth,
    sortedMonthKeys,
    weekendsAccessors,
    lastDayOfMonthAccessors,
  };
};

export const getYears = (date: string) => {
  const currentYear = getYear(new Date(date));
  const previousYear = currentYear - 1;
  const nextYear = currentYear + 1;

  return {
    previousYear,
    currentYear,
    nextYear,
  };
};
