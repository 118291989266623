import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  range: {
    width: '170px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingY: 0.5,
    paddingX: 1,
    color: 'primary.blue',
    backgroundColor: 'background.frostBlue',
    borderRadius: '32px',
  },
  apply: {
    width: '40px',
    height: '40px',
    backgroundColor: 'button.main',
  },
  xIcon: {},
  clearButton: {
    padding: 0,
    backgroundColor: 'transparent',
    borderRadius: '50%',
  },
  applyButton: {
    width: '40px',
    height: '40px',
    borderRadius: '44px',
    padding: 0,
  },
  loader: {
    width: '40px',
    height: '40px',
    padding: 0,
  },
};

export default styles;
